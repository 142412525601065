<template>
  <div>
    <b-modal
      id="interv-img-modal"
      ref="interv-img-modal"
      size="xl"
    >
      <div class="row">
        <div class="col-12 col-lg-12">
          <img :src=img width="100%" />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["intervention"],
  created() {
  },
  data() {
    return {
      img: null,
    };
  },
  watch: {
    intervention(newVal) {
      console.log(newVal);
      const blob = new Blob([newVal.data], { type: newVal.headers['content-type'] });
      const imageUrl = URL.createObjectURL(blob);
      this.img = imageUrl;
    },
  },
  methods: {
  },
};
</script>