<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import InterventionsData from "./interv-mobile-data";

import {
  layoutComputed,
  authComputed,
  interventionsMobileMethods,
  interventionsMobileComputed,
} from "@/state/helpers";
/**
 * Interventions component
 */
export default {
  page: {
    title: "Interventions",
    meta: [{ name: "Interventions" }],
  },
  components: {
    Layout,
    PageHeader,
    InterventionsData,
  },
  mounted() {
    this.retrieveInterventions({});
  },
  data() {
    return {
      title: "Interventions",
      busy: false,

      currentPage: 1,
      perPage: 10,

      loaderDashboard: false,

      interventionsData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...interventionsMobileComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: "Interventions",
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...interventionsMobileMethods,

    retrieveInterventions({page, perPage}) {
      this.busy = true;
      return this.getInterventionsBy({ 
        page: page ? page : this.currentPage,
        limit: perPage ? perPage : this.perPage,
        sortBy: '-createdAt',
        populate: 'user,province,region' 
      }).then(() => {
      this.busy = false;
      });
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-intervention-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-intervention-modal");
    },
    add(intervention) {
      this.addIntervention(intervention)
        .then((intervention) => {
          this.makeToast("Add intervention", intervention.name+" has been added", "success")
        })
        .catch(error => {
          this.makeToast("Add intervention", error, "danger")
        });
    },
    handlePageChange(value) {
      this.currentPage = value;
      this.retrieveInterventions({});
    },
  },
  watch: {
    getInterventions(newVal) {
      this.interventionsData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <!-- <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addIntervention.text")}}
        </b-button>
        <AddIntervention @addIntervention="add" />
      </div>
    </div> -->
    <div class="row">
      <div class="col-12">
        <InterventionsData v-bind:interventions="interventionsData" :busy="busy" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="dataTables_paginate paging_simple_numbers float-right">
          <ul class="pagination pagination-rounded">
            <!-- pagination -->
            <b-pagination
              v-model="currentPage"
              :total-rows="getInterventions.totalResults"
              :per-page="perPage"
              @change="handlePageChange"
            ></b-pagination>
          </ul>
        </div>
      </div>
    </div>
  </Layout>
</template>