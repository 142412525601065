<script>
/**
 * Interventions Data component
 */
import moment from "moment";
import { authComputed, interventionsMobileMethods } from "@/state/helpers";
import IntervImg from "./interv-img.vue";
export default {
  props: ["interventions", "busy", "loaderStatus"],
  components: {
    IntervImg
  },
  data() {
    return {
      intervention: null,
      interventionImg: null,
      interventionsList: [],
    };
  },
  computed: {
    ...authComputed,
    fieldsComputed() {
      if (this.loggedIn.user.role != 'USER' && this.loggedIn.user.role != 'OPERATOR') {
        return [
          { key: "date", sortable: false, label: this.$t("dataTable.date.text"), class: "tdClass" },
          { key: "user", sortable: false, label: this.$t("dataTable.user.text"), class: "tdClass" },
          { key: "type", sortable: false, label: this.$t("dataTable.type.text"), class: "tdClass" },
          { key: "region", sortable: false, label: this.$t("dataTable.region.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
          { key: "state", sortable: false, label: this.$t("dataTable.status.text"), class: "tdClass" },
          /* { key: "actions", sortable: false, label: this.$t("dataTable.tools.text"), class: "tdClass" }, */
        ];
      } else {
        return [
          { key: "date", sortable: false, label: this.$t("dataTable.date.text"), class: "tdClass" },
          { key: "user", sortable: false, label: this.$t("dataTable.user.text"), class: "tdClass" },
          { key: "type", sortable: false, label: this.$t("dataTable.type.text"), class: "tdClass" },
          { key: "region", sortable: false, label: this.$t("dataTable.region.text"), class: "tdClass" },
          { key: "province", sortable: false, label: this.$t("dataTable.province.text"), class: "tdClass" },
          { key: "state", sortable: false, label: this.$t("dataTable.status.text"), class: "tdClass" },
        ];
      }
    },
  },
  mounted() {},
  methods: {
    ...interventionsMobileMethods,
    showModalImg() {
      this.$bvModal.show("interv-img-modal");
    },
    hideModalImg() {
      this.$bvModal.hide("interv-img-modal");
    },
    showModalEdit() {
      this.$bvModal.show("edit-intervention-modal");
    },
    hideModalEdit() {
      this.$bvModal.hide("edit-intervention-modal");
    },
    edit(intervention) {
      this.updateIntervention(intervention)
        .then((intervention) => {
          this.makeToast(
            "Update intervention",
            intervention.name + " has been updated",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Update intervention", error, "danger");
        });
    },
    passIntervention(type, intervention) {
      if (type == "edit") {
        this.intervention = intervention;
        this.showModalEdit();
      } else if (type == "delete") {
        this.$swal
          .fire({
            title: this.$t('modals.askValidDelete.text'),
            text: this.$t('modals.cantRevert.text'),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: this.$t("buttons.validDelete.text"),
            cancelButtonText: this.$t("buttons.cancel.text"),
            showLoaderOnConfirm: true,
            preConfirm: () => {
              this.delIntervention(intervention.id)
                .then((response) => {
                  return response;
                })
                .catch((error) => {
                  this.$swal.showValidationMessage(`Request failed: ${error}`);
                });
            },
            allowOutsideClick: () => !this.$swal.isLoading(),
          })
          .then((result) => {
            if (result.isConfirmed) {
              this.$swal.fire(
                "Deleted!",
                "The intervention has been deleted.",
                "success"
              );
            }
          })
          .catch((error) => {
            this.makeToast("Alert Error", error, "danger");
          });
      }
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    async rowClicked(record) {
      //this.$router.push({name:'alert', params: { serial: record.serial }})
      this.intervention = record.id
      this.interventionImg = await this.getInterventionImg(this.intervention)
      this.showModalImg()
    },
  },
  watch: {
    interventions(newVal) {
      this.interventionsList = []
      newVal.map((obj) => {
        this.interventionsList.push({
          ...obj, 
          date: moment(obj.createdAt).format("DD-MM-YYYY HH:mm:ss"),
          user: obj.user.fullName, 
          province: obj.province.name, 
          region: obj.region.name});
      })
    },
  },
};
</script>

<template>
  <div>
    <div
      class="table-responsive mb-3 bg-white rounded-lg"
      style="min-height: 350px"
    >
      <b-table
        class="mb-0"
        hover
        show-empty
        centred
        :busy="busy"
        :empty-text="$t('dataTable.noInterventions.text')"
        :items="interventionsList"
        :fields="fieldsComputed"
        v-bind:style="{ cursor: `pointer` }"
        stacked="sm"
        @row-clicked="rowClicked"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <strong>{{$t('dataTable.loading.text')}}</strong>
          </div>
        </template>
        <template v-slot:cell(state)="row">
          <div
            class="badge font-size-12 text-center"
            :class="{
              'badge-soft-warning': `${row.item.state}` === 'PENDING',
              'badge-soft-success': `${row.item.state}` === 'ACCEPTED',
              'badge-soft-danger': `${row.item.state}` === 'REJECTED',
            }"
          >
            {{ row.item.state }}
          </div>
        </template>
        <!-- <template v-slot:cell(type)="row">
          <div class="d-inline-flex align-items-center">
            {{row.item.user.fullName}}
          </div>
        </template> -->
      </b-table>
      <IntervImg v-bind:intervention="interventionImg"/>
    </div>
  </div>
</template>
<style>
.tdClass {
  text-align: center;
}
</style>